<template>
  <div>
    <b-card no-body>
      <b-card-header header-bg-variant="secondary" header-text-variant="dark" class="p-1">
        <img src="@/icon/1519119.png" style="width: 1.5em;" />
        <b-button class="pull-right" size="sm" :variant="d_meetingData && d_meetingData.id ? 'warning' : 'secondary'" @click="d_meetingScreen = true" :title="d_meetingData && d_meetingData.id ? 'TOPLANTI AKTİF' : 'TOPLANTIYA BAĞLANMAK TIKLAYINIZ'" style="margin-right: 5px;">
          <img src="@/icon/1994875.png" style="width: 2em;" /> toplantı
        </b-button>
        <b-button class="pull-right" size="sm" @click="f_createNewGroup()" title="YENİ GRUP OLUŞTUR" style="margin-right: 5px;">
          <img src="@/icon/1753831.png" style="width: 2em;" /> yeni grup
        </b-button>
        <template v-if="d_meetingData && d_meetingData.id">
          <b-button v-if="!d_sharingCamera" class="pull-right" size="sm" @click="f_shareCamera()" title="Ekranı Paylaş" style="margin-right: 5px;">
            <img src="@/icon/22160.png" style="width: 2em;" /> kamera aç
          </b-button>
          <b-button v-if="d_sharingCamera" class="pull-right" size="sm" @click="f_stopCameraShare()" title="Ekranı Paylaş" style="margin-right: 5px;">
            <img src="@/icon/22160.png" style="width: 2em;" /> kamera kapat
          </b-button>
          <b-button v-if="!d_sharingAudio" class="pull-right" size="sm" @click="f_shareAudio()" title="Ekranı Paylaş" style="margin-right: 5px;">
            <img src="@/icon/22160.png" style="width: 2em;" /> ses aç
          </b-button>
          <b-button v-if="d_sharingAudio" class="pull-right" size="sm" @click="f_stopAudioShare()" title="Ekranı Paylaş" style="margin-right: 5px;">
            <img src="@/icon/22160.png" style="width: 2em;" /> sesi kapat
          </b-button>
          <b-button v-if="!d_sharingScreen && !d_otherOneShare" class="pull-right" size="sm" @click="f_shareScreen()" title="Ekranı Paylaş" style="margin-right: 5px;">
            <img src="@/icon/22160.png" style="width: 2em;" /> ekran paylaş
          </b-button>
          <b-button v-if="d_sharingScreen" class="pull-right" size="sm" @click="f_stopScreenShare()" title="Paylaşımı Durdur" style="margin-right: 5px;">
            <img src="@/icon/22160.png" style="width: 2em;" /> paylaşımı durdur
          </b-button>
          <template v-if="d_otherOneShare || (!d_otherOneShare && d_showMessengerMode === 'camera_screen')">
            <b-button v-if="d_otherOneShare && (d_showMessengerMode === 'camera_screen' || d_showMessengerMode === 'message_screen')" class="pull-right" size="sm" @click="d_showMessengerMode = 'share_screen'" title="paylaşım ekranı" :style="d_openCloseLight.show_messenger_mode ? 'margin-right: 5px; box-shadow: 0px 0px 4px 4px rgb(233, 253, 233);' : 'margin-right: 5px;'">
              <img src="@/icon/22160.png" style="width: 2em;" /> paylaşım ekranı
            </b-button>
            <b-button v-if="d_showMessengerMode === 'share_screen' || d_showMessengerMode === 'camera_screen'" class="pull-right" size="sm" @click="d_showMessengerMode = 'message_screen'" title="mesaj ekranı" style="margin-right: 5px;">
              <img src="@/icon/22160.png" style="width: 2em;" /> mesaj ekranı
            </b-button>
          </template>
          <b-button v-if="d_showMessengerMode === 'share_screen' || d_showMessengerMode === 'message_screen'" class="pull-right" size="sm" @click="d_showMessengerMode = 'camera_screen'" title="kamera ekranı" style="margin-right: 5px;">
            <img src="@/icon/22160.png" style="width: 2em;" /> kamera ekranı
          </b-button>
        </template>
      </b-card-header>
      <b-row style="margin: 0px;">
        <b-col cols="4" style="padding: 3px;">
          <b-card bg-variant="white" style="margin-bottom: 0px;" no-body>
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <b-row style="margin: 0px;">
                <b-col cols="6">
                  <i class="fa fa-file-text-o"></i> Kullanıcılar
                </b-col>
                <b-col cols="6" style="padding: 3px; text-align: right;">
                  <select v-model="d_showUserMode" style="padding: 3px; background-color: #99e8e97a; border: 0px;">
                    <option value="all">hepsi</option>
                    <option value="ai_robot">yapay zeka robotları</option>
                    <option value="my_hospital">kuruma ait</option>
                    <option value="supporters">destek</option>
                    <option v-if="d_meetingData && d_meetingData.id" value="meeting">toplantı</option>
                  </select>
                </b-col>
              </b-row>
            </b-card-header>
            <b-row>
              <b-col cols="12">
                <b-form-input v-model="user_search" placeholder="arama...." style="background-color: transparent;"></b-form-input>
              </b-col>
            </b-row>
            <div :style="f_calculateStyle()">
              <template v-for="(mes, mes_ind) in messenger_data">
                <template v-if="f_searchUser(mes)">
                  <b-row :style="mes_ind === selected_mes_ind ? 'cursor: pointer; min-height: 30px; background-color: #e5f1ec; margin: 0px; border-bottom: solid 1px #a8efc1;' : 'cursor: pointer; min-height: 30px; margin: 0px; border-bottom: solid 1px #a8efc1;'">
                    <b-col sm="12" md="10" @click="f_selectThisUser(mes_ind)">
                      <strong>
                      <template v-if="mes.user">
                        <img v-if="mes.user.ai_robot" src="@/icon/2324511.png" style="width: 2em;" />
                        <img v-if="!mes.user.ai_robot" src="@/icon/9370.png" style="width: 2em;" />
                        {{ mes.user.first_name + ' ' + mes.user.last_name }}                      
                      </template>
                      <template v-else-if="mes.group">
                        <img src="@/icon/2196504.png" style="width: 2em;" />
                        {{ mes.group.label }}                      
                      </template>
                    </strong>
                      <!-- <small class="text-muted">&nbsp;&nbsp;On-line</small> -->
                    </b-col>
                    <b-col sm="12" md="2">
                      <b-badge pill variant="warning" v-if="f_hasNotSeenMessage(mes)"> yeni </b-badge>
                      <template v-if="d_meetingData && d_meetingData.id && mes.user">
                        <template v-if="d_talkingUsers.indexOf(mes.user.username) !== -1">
                          <div :id="'audio_' + mes.user.username" style="display: hidden;"></div>
                        </template>
                        <template v-if="mes_ind === selected_mes_ind || (d_activeMeetingParticipants.indexOf(mes.user.username) !== -1)">
                          <b-button size="sm" :variant="d_activeMeetingParticipants.indexOf(mes.user.username) !== -1 ? 'warning' : 'secondary'" @click="f_sendMessage('meeting', d_meetingData.id)">
                            <img src="@/icon/736649.png" style="width: 2em;" />
                          </b-button>
                        </template>
                      </template>
                    </b-col>
                  </b-row>
                </template>
              </template>
            </div>
            <!--             <b-card-footer footer-bg-variant="white" header-text-variant="dark" class="p-1">
              <b-row>
                <b-col sm="12" md="2">
                  <img src="@/icon/2547611.png" style="width: 2.5em">
                </b-col>
                <b-col sm="12" md="10">
                  <b-button :variant="d_showAiRobots ? 'primary' : 'secondary'" size="md" style="width: 100%;" @click="d_showAiRobots === true ? d_showAiRobots = false : d_showAiRobots = true">
                    yapay zeka robotları
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
 --></b-card>
        </b-col>
        <b-col cols="8" style="padding: 3px;">
          <b-card v-if="d_showMessengerMode === 'message_screen'" bg-variant="white" style="margin-bottom: 0px;" no-body>
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-file-text-o"></i> Mesajlaşma Ekranı
            </b-card-header>
            <div id="messages-list" :style="f_calculateStyleMessageScreen()">
              <template v-if="selected_mes_ind !== ''">
                <template v-for="(message, message_ind) in d_selectedMessengerData.message_list">
                  <b-row v-if="message && message.sender" :key="'messenger_data_selected_mes_ind_.message_' + message_ind" style="margin: 0px; padding: 5px;">
                    <b-col cols="6" style="padding-left: 0px; ">
                      <template v-if="message.sender !== user.username">
                        <template v-if="d_selectedMessengerData.user && d_selectedMessengerData.user.ai_robot">
                          <img src="@/icon/2324511.png" style="width: 4em;" />
                        </template>
                        <template v-else>
                          <img src="@/icon/9370.png" style="width: 2em;" />
                          <span v-if="d_selectedMessengerData.group" style="width: 100%; padding: 5px; color: black; font-size: 12px;">
                          {{ f_getUserNameFromGroupList(message.sender) }}
                        </span>
                        </template>
                        <div style="width: 100%; padding: 5px; color: black; font-size: 12px; border: solid 1px #b7b7b7; border-radius: 5px 5px; background-color: #d4e9ef80;">
                          {{ message.message }}
                        </div>
                        <b-row style="margin: 0px;">
                          <b-col cols="12" style="padding: 0px;">
                            <span class="pull-right" style="font-size: 10px;background-color: #cee2e6;color: black;border: solid 1px #9e9e9e;border-radius: 5px 5px;"> {{ DateFormat(message.date) }} </span>
                          </b-col>
                        </b-row>
                        <b-row v-if="message.meeting_id" style="margin: 0px;">
                          <b-col cols="12" style="padding: 0px;">
                            <b-button class="pull-right" size="sm" variant="warning" @click="f_joinMeeting(message.meeting_id)">
                              <img src="@/icon/1994875.png" style="width: 2em;" /> Toplantıya katılmak için lütfen tıklayınız.
                            </b-button>
                          </b-col>
                        </b-row>
                      </template>
                    </b-col>
                    <b-col cols="6" style="padding-right: 0px; ">
                      <template v-if="message.sender === user.username">
                        <img :src="profile_picture" class="img-avatar" style="width: 2em;" />
                        <div style="width: 100%; padding: 5px; color: black; font-size: 12px; float: right; border: solid 1px #b7b7b7; border-radius: 5px 5px; background-color: #dbefd480;">
                          {{ message.message }}
                        </div>
                        <div class="pull-right">
                          <span style="font-size: 10px;background-color: #bcc7a4;color: black;border: solid 1px #9e9e9e;border-radius: 5px 5px;"> {{ DateFormat(message.date) }} </span>
                        </div>
                      </template>
                    </b-col>
                  </b-row>
                </template>
              </template>
            </div>
            <b-row style="margin: 0px; padding: 3px;">
              <b-col cols="12">
                <template v-for="(sen, sen_ind) in d_recommendNow">
                  <span style="margin-right: 20px; cursor: pointer; border-radius: 3px 3px; background-color: #9ae0dc; border: solid 1px #abafaf;"> {{ sen.label }} </span>
                </template>
              </b-col>
            </b-row>
            <div>
              <b-form-group :disabled="selected_mes_ind === ''" style="margin: 0px;">
                <b-input-group>
                  <!-- @keydown.enter.native="f_sendMessage" -->
                  <b-form-textarea id="sending-text" @keydown.enter.native="f_sendMessage" v-model="d_sendText" placeholder="Mesajınızı yazınız..." rows="3" max-rows="6"></b-form-textarea>
                  <b-input-group-append>
                    <b-button @click="f_sendMessage()" variant="success"> <i class="fa fa-send"></i> gönder </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card>
          <b-card v-if="d_showMessengerMode === 'share_screen'" bg-variant="white" style="margin-bottom: 0px;" no-body :class="[d_screenSharingModejFullScreen ? 'full-screen-mode' : '']">
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-file-text-o"></i> Ekran Paylaşım Modu
              <b-button variant="secondary" class="pull-right" @click="d_screenSharingModejFullScreen === true ? d_screenSharingModejFullScreen = false : d_screenSharingModejFullScreen = true " style="margin-right: 3px; padding: 0px; border: solid 1px #5d7d75;" :title="$t('wdm16.6589')">
                <img src="@/icon/900293.png" style="width: 2em;">
              </b-button>
            </b-card-header>
            <div id="video_element" :style="f_calculateStyleMessageScreen()">
              Ekran Paylaşım Alanı
            </div>
          </b-card>
          <b-card v-if="d_showMessengerMode === 'camera_screen'" bg-variant="white" style="margin-bottom: 0px;" no-body :class="[d_cameraSharingModejFullScreen ? 'full-screen-mode' : '']">
            <b-card-header header-bg-variant="success" header-text-variant="dark" class="p-1">
              <i class="fa fa-file-text-o"></i> Kamera Paylaşım Modu
              <b-button variant="secondary" class="pull-right" @click="d_cameraSharingModejFullScreen === true ? d_cameraSharingModejFullScreen = false : d_cameraSharingModejFullScreen = true " style="margin-right: 3px; padding: 0px; border: solid 1px #5d7d75;" :title="$t('wdm16.6589')">
                <img src="@/icon/900293.png" style="width: 2em;">
              </b-button>
            </b-card-header>
            <div id="video_element" :style="f_calculateStyleMessageScreen()">
              <b-row id="camera_area">
                <template v-for="(mes, mes_ind) in messenger_data">
                  <template v-if="d_cameraUsers.indexOf(mes.user.username) !== -1">
                    <b-col cols="3">
                      <div :id="'camera_' + mes.user.username"></div>
                    </b-col>
                  </template>
                </template>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <modal v-if="d_modalMessengerGroup" @close="d_modalMessengerGroup = false" :width="'750'">
      <h3 slot="header"> Mesajlaşma Grubu Ekranı </h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="'wdm20'" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveMessengerGroup()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_modalMessengerGroup = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal title="Toplantıya Katıl" centered class="modal-success" v-model="d_meetingScreen" @ok="d_meetingScreen = false" ok-variant="success" hide-footer>
      <template v-if="!d_meetingData">
        <b-row>
          <b-col cols="3"> <strong>Toplantıya Katıl</strong> </b-col>
          <b-col cols="6">
            <b-form-input type="text" class="form-control-warning" v-model="d_meetingId"></b-form-input>
          </b-col>
          <b-col cols="3">
            <b-button v-if="d_meetingId" size="sm" variant="secondary" @click="f_joinMeeting(d_meetingId)">
              <img src="@/icon/1994875.png" style="width: 2em;" /> Katıl
            </b-button>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col cols="12">
            <b-button variant="secondary" @click="f_createNewMeeting()">yeni toplantı oluştur</b-button>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row>
          <b-col cols="6">
            <b-button variant="secondary" @click="f_leaveMeeting('this')">Toplantıdan Ayrıl</b-button>
          </b-col>
          <b-col cols="6">
            <b-button variant="secondary" @click="f_leaveMeeting('all')">Toplantıyı Sonlandır</b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
var mediaScreenRecorder;
var mediaAudioRecorder;
var mediaCameraRecorder;
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import { ClsWdmrForever } from "@/services/public/wdm";
import { mapGetters } from "vuex";
import {
  default as router
} from "@/router";
// services
import OptionService from '@/services/option';
import MessengerService from "@/services/messenger";
import moment from "moment";
import { ClsMessenger } from '@/services/public/messenger';
import {
  default as Modal
} from "@/components/widgets/Modal";

export default {
  name: "Messenger",
  computed: {
    ...mapGetters({
      lang: "lang",
      messenger_data: 'messenger_data',
      StoreModal: 'modal',
      help: 'help',
      StoreLoading: 'loading',
      StoreTest: 'test'
    })
  },
  props: {
    polestar: {
      type: Object,
      required: false
    },
    organization_data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      d_selectedAnatomySchemaList: [],
      d_screenSharingModejFullScreen: false,
      d_cameraSharingModejFullScreen: false,
      d_showUserMode: 'all', // my_hospital, meeting, supporter
      d_hoverUserIndex: '',
      d_activeMeetingParticipants: [],
      d_meetingId: '',
      d_meetingScreen: false,
      d_recommendSelection: { 'start': 0, 'end': 2 },
      d_recommendationSentenceList: [{ 'label': 'hangi bilgiler seçildi', 'translation': { 'tr': 'hangi bilgiler seçildi' } }, { 'label': 'sonlandır', 'translation': { 'tr': 'sonlandır' } }, { 'label': 'yeni analize başla', 'translation': { 'tr': 'yeni analize başla' } }, { 'label': 'analiz yap', 'translation': { 'tr': 'analiz yap' } }, { 'label': 'evet', 'translation': { 'tr': 'evet' } }, { 'label': 'hayır', 'translation': { 'tr': 'hayır' } }, { 'label': 'analiz yap', 'translation': { 'tr': 'analiz yap' } }],
      d_recommendNow: [],
      d_showAiRobots: false,
      selected_mes_ind: '',
      user_search: '',
      d_sendText: '',
      user: {},
      profile_picture: '',
      refreshIntervalId: '',
      d_modalMessengerGroup: false,
      wisdom_data: {},
      data_type: 'wdm20',
      option_data: { 'wdm20': {} },
      d_selectedMessengerData: {},
      d_pagination: {
        'start': 0,
        'end': 10,
        'current': 1,
        'perpage': 10
      },
      d_meetingData: '',
      d_meetingLabel: '',
      d_shareScreenInterval: '',
      d_shareAudioInterval: '',
      d_shareCameraInterval: '',
      d_showMessengerMode: 'message_screen',
      d_sharingScreen: false,
      d_sharingAudio: false,
      d_sharingCamera: false,
      d_otherOneShare: false,
      d_openCloseLight: {
        'show_messenger_mode': false
      },
      d_talkingUsers: [],
      d_cameraUsers: []
    };
  },
  created: function () {
    // wca_start
    // We are getting the talk list, messenger list while creating this component, because maybe a new message had come. But there was a socket problem. Then the user could not see the message.
    ClsMessenger.get_messenger_data();
    // wca_end
    this.user = JSON.parse(localStorage.getItem('user'));
    let prof_pic = localStorage.getItem('profile_picture');
    if (!prof_pic || prof_pic === undefined || prof_pic === 'undefined' || prof_pic === '') {
      this.profile_picture = 'img/doctor_man.png';
    } else {
      this.profile_picture = JSON.parse(prof_pic);
    }
    let query = 'data_type=wdm20&location=wisdom';
    OptionService.get_last_wdm_of_this_type(query)
      .then(resp => {
        if (resp.data.status === 'success') {
          this.option_data['wdm20'] = resp.data.result;
        } else {
          console.log('errror ', resp.data.message);
        }
      });
    this.addEventListenerToMessageListScroll();
  },
  mounted () {
    try {
      let x = JSON.parse(localStorage.getItem('meeting'));
      // console.log('x: ', x);
      this.d_meetingData = x ? x : '';
    } catch (err) {}
    this.f_createParticipantList();
    if (!this.profile_picture || this.profile_picture === undefined || this.profile_picture === 'undefined' || this.profile_picture === '') {
      this.profile_picture = 'img/doctor_man.png';
    }
    this.$options.sockets.onopen = function (event) {
      let meeting_data = JSON.parse(localStorage.getItem('meeting'));
      if (meeting_data && meeting_data['id']) {
        let data = { 'id': meeting_data['id'] };
        MessengerService.get_meeting_data(data)
          .then(resp => {
            if (resp.data.status === 'success') {
              if (resp.data.result.wdmr_69) {
                localStorage.setItem('meeting', JSON.stringify(resp.data.result.wdmr_69));
                this.d_meetingData = resp.data.result.wdmr_69;
                this.f_createParticipantList();
              } else {
                localStorage.removeItem('meeting');
              }
            } else {
              console.log('error', resp.data.message);
            }
          });
      }
    };
    this.$options.sockets.onmessage = function (message) {
      let socket_data = JSON.parse(message.data);
      // console.log(socket_data.type);
      if (socket_data.type === 'meeting_share_screen') {
        // this.videoElem.src = socket_data.capture_stream;
        // console.log(socket_data.capture_stream);
        // console.log(socket_data.screen_video);
        if (this.d_showMessengerMode === 'share_screen') {
          let vid_element = document.createElement('video');
          vid_element.id = 'video';
          vid_element.src = socket_data.screen_video;
          // vid_element.style.width = '100%';
          let vid_height = '100%';
          let x = document.getElementById('video_element');
          if (x) {
            vid_height = x.offsetHeight.toString() + 'px';
          }
          vid_element.style.height = vid_height;
          vid_element.style.backGround = 'transparent';
          vid_element.autoplay = 'autoplay';
          vid_element.volume = 0.2;
          // vid_element.innerHTML = 'Your browser does not support the video tag.';
          vid_element.load();
          setTimeout(function () {
            if (document.getElementById('video_element') && vid_element.readyState === 4) {
              // console.log('height ', document.getElementById('video_element').offsetHeight);
              document.getElementById('video_element').innerHTML = '';
              document.getElementById('video_element').appendChild(vid_element);
            }
          }.bind(this), 250)
        } else {
          this.d_openCloseLight.show_messenger_mode = true;
          setTimeout(function () {
            this.d_openCloseLight.show_messenger_mode = false;
          }.bind(this), 1000)
        }
      }
      if (socket_data.type === 'meeting_share_camera') {
        if (this.d_showMessengerMode === 'camera_screen') {
          let vid_element = document.createElement('video');
          vid_element.id = 'video';
          vid_element.src = socket_data.screen_video;
          // vid_element.style.width = '100%';
          let vid_width = '100%';
          let x = document.getElementById('camera_area');
          // console.log(x.offsetWidth);
          if (x) {
            vid_width = (x.offsetWidth / 4).toString() + 'px';
          }
          vid_element.style.width = vid_width;
          vid_element.style.backGround = 'transparent';
          vid_element.autoplay = 'autoplay';
          vid_element.volume = 0.2;
          // vid_element.innerHTML = 'Your browser does not support the video tag.';
          vid_element.load();
          setTimeout(function () {
            if (document.getElementById('camera_' + socket_data.send_by) && vid_element.readyState === 4) {
              // console.log('height ', document.getElementById('camera_' + socket_data.send_by).offsetHeight);
              document.getElementById('camera_' + socket_data.send_by).innerHTML = '';
              document.getElementById('camera_' + socket_data.send_by).appendChild(vid_element);
            }
          }.bind(this), 250)
        }
      }
      // console.log(socket_data);
      if (socket_data.type === 'meeting_share_audio') {
        // console.log('meeting_share_audio ', socket_data.send_by);
        if (this.d_talkingUsers.indexOf(socket_data.send_by) !== -1) {
          let audio_element = document.createElement('audio');
          audio_element.autoplay = 'autoplay';
          // console.log(socket_data.audio ? socket_data.audio.substring(0, 30) : 'no audio');
          audio_element.src = socket_data.audio;
          audio_element.load();
          let audio_id = 'audio_' + socket_data.send_by;
          let audio_location = document.getElementById(audio_id);
          // console.log(audio_location);
          setTimeout(function () {
            document.getElementById(audio_id).innerHTML = '';
            document.getElementById(audio_id).appendChild(audio_element);
          }.bind(this), 200)
        }
      }
      if (socket_data.type === 'meeting_info') {
        if (socket_data.data.type === 'leave_meeting') {
          // console.log('socket_data.data.leave_type ', socket_data.data.leave_type);
          if (socket_data.leave_type === 'all') {
            localStorage.removeItem('meeting');
            this.d_meetingData = '';
          } else if (socket_data.leave_type === 'this') {
            if (this.d_meetingData && this.d_meetingData.id) {
              for (let i in this.d_meetingData.data.general.participant.list) {
                if (this.d_meetingData.data.general.participant.list[i].participant_name.val.value === socket_data.user) {
                  this.d_meetingData.data.general.participant.list[i].participant_status.val = { 'label': 'pasif', 'value': 'passive' };
                  break;
                }
              }
              localStorage.setItem('meeting', JSON.stringify(this.d_meetingData));
            }
          }
        }
        if (socket_data.data.type === 'meeting_share') {
          if (socket_data.data.wdmr_69) {
            // console.log('meeting_share socket_data.wdmr_69 is filled');
            localStorage.setItem('meeting', JSON.stringify(socket_data.wdmr_69));
            this.d_meetingData = socket_data.data.wdmr_69;
          } else {
            // console.log('meeting_share socket_data.wdmr_69 is freeeeeeeeeeeeee');
            this.d_meetingData = '';
          }
          this.f_createParticipantList();
        }
        if (socket_data.data.type === 'join_meeting') {
          localStorage.setItem('meeting', JSON.stringify(socket_data.data.wdmr_69));
          this.d_meetingData = socket_data.data.wdmr_69;
          this.f_createParticipantList();
        }
        this.$forceUpdate();
      }
      if (socket_data.type && ['messenger_new_message'].indexOf(socket_data.type) !== -1) {
        if (socket_data.data.message_data.group_key) {
          for (let mes_ind in this.messenger_data) {
            if (this.selected_mes_ind !== '' && this.messenger_data[mes_ind].group && this.messenger_data[mes_ind].group.key === socket_data.data.message_data.group_key && parseInt(this.selected_mes_ind) === parseInt(mes_ind)) {
              this.d_selectedMessengerData.message_list.push(socket_data.data.message_data.message);
              let x = JSON.parse(JSON.stringify(this.messenger_data));
              for (let dy_ind in x[mes_ind].day_list) {
                if (x[mes_ind].day_list[dy_ind].new) {
                  this.$delete(x[mes_ind].day_list[dy_ind], 'new');
                }
              }
              this.$store.commit('messenger_data', x);
              this.$forceUpdate();
              this.f_messagesScrollFunc();
              break;
            }
          }
        } else {
          for (let mes_ind in this.messenger_data) {
            if (this.selected_mes_ind !== '' && this.messenger_data[mes_ind].user && this.messenger_data[mes_ind].user.username === socket_data.data.user && parseInt(this.selected_mes_ind) === parseInt(mes_ind)) {
              this.d_selectedMessengerData.message_list.push(socket_data.data.message_data.message);
              let x = JSON.parse(JSON.stringify(this.messenger_data));
              for (let dy_ind in x[mes_ind].day_list) {
                if (x[mes_ind].day_list[dy_ind].new) {
                  this.$delete(x[mes_ind].day_list[dy_ind], 'new');
                }
              }
              this.$store.commit('messenger_data', x);
              this.$forceUpdate();
              this.f_messagesScrollFunc();
              break;
            }
          }
        }
      }
    };

    this.f_messagesScrollFunc();
    if (this.polestar) {
      this.d_showAiRobots = true;
      this.f_recommendText();
    }
  },
  methods: {
    f_meetingShare: function (type) {
      let data = {
        'type': type,
        'share_mode': '',
        'id': this.d_meetingData.id
      };
      if (type === 'audio') {
        if (this.d_sharingAudio) {
          data.share_mode = 'yes';
        } else {
          data.share_mode = 'no';
        }
      } else if (type === 'camera') {
        if (this.d_sharingCamera) {
          data.share_mode = 'yes';
        } else {
          data.share_mode = 'no';
        }
      } else if (type === 'screen') {
        if (this.d_sharingScreen) {
          data.share_mode = 'yes';
        } else {
          data.share_mode = 'no';
        }
      }
      // console.log('meeting_share ', data);
      MessengerService.meeting_share(data)
        .then(resp => {
          // console.log(resp.data.result);
          if (resp.data.status === 'success') {
            this.d_meetingData = resp.data.result.wdmr_69;
            this.f_createParticipantList();
            localStorage.setItem('meeting', JSON.stringify(resp.data.result.wdmr_69));
          } else {
            localStorage.removeItem('meeting');
            alert('Error: ', resp.data.message);
          }
        })
    },
    f_stopScreenShare: function () {
      // this.d_shareScreenInterval = 0;
      this.d_sharingScreen = false;
      this.d_showMessengerMode = 'message_screen';
      if (mediaScreenRecorder && mediaScreenRecorder.status === 'recording') {
        mediaScreenRecorder.stop();
      }
      clearInterval(this.d_shareScreenInterval);
      this.f_meetingShare('screen');
    },
    f_stopAudioShare: function () {
      this.d_sharingAudio = false;
      if (mediaAudioRecorder && mediaAudioRecorder.status === 'recording') {
        mediaAudioRecorder.stop();
      }
      clearInterval(this.d_shareAudioInterval);
      this.f_meetingShare('audio');
    },
    f_stopCameraShare: function () {
      this.d_sharingCamera = false;
      if (mediaCameraRecorder && mediaCameraRecorder.status === 'recording') {
        mediaCameraRecorder.stop();
      }
      clearInterval(this.d_shareCameraInterval);
      this.f_meetingShare('audio');
    },
    f_shareScreen: function () {
      this.d_sharingScreen = true;
      let vm = this;

      function file_read(blob) {
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          // console.log(reader.result);
          let client_data = {
            'type': 'meeting_share_screen',
            'screen_video': reader.result,
            'active_participants': vm.d_activeMeetingParticipants,
            'send_by': vm.user.username
          }
          vm.$socket.send(JSON.stringify(client_data));
        }, false);
        reader.readAsDataURL(blob);
      }
      async function startCapture() {
        await navigator.mediaDevices.getDisplayMedia({ 'video': { 'cursor': 'always' }, 'audio': false })
          .then(function (stream) {
            vm.f_meetingShare('screen');
            var options;
            let is_type_supported = MediaRecorder.isTypeSupported('video/webm;codecs=vp8');
            if (is_type_supported) {
              options = { mimeType: 'video/webm;codecs=vp8' };
              mediaScreenRecorder = new MediaRecorder(stream, options);
            } else {
              mediaScreenRecorder = new MediaRecorder(stream);
            }
            // var options = {mimeType: 'video/webm;codecs=vp8'};
            // mediaScreenRecorder = new MediaRecorder(stream, options);
            mediaScreenRecorder = new MediaRecorder(stream, options);
            // console.log('mediaScreenRecorder.mimeType', mediaScreenRecorder.mimeType);
            mediaScreenRecorder.start();
            var chunks = [];
            mediaScreenRecorder.ondataavailable = function (e) {
              chunks.push(e.data);
            }
            mediaScreenRecorder.onstop = function (e) {
              // console.log('chunks ', chunks);
              if (chunks.length > 0) {
                // let blob = new Blob(chunks, { type: "video/mp4" });
                let blob = null;
                if (is_type_supported) {
                  blob = new Blob(chunks, { type: 'video/webm;codecs=vp8' });
                } else {
                  blob = new Blob(chunks, { type: 'video/webm' });
                }
                file_read(blob);
              }
            }
            vm.d_shareScreenInterval = setInterval(() => {
              if (mediaScreenRecorder.state === 'recording') {
                if (vm.d_shareScreenInterval) {
                  mediaScreenRecorder.stop();
                  chunks = [];
                  mediaScreenRecorder.start();
                } else {
                  mediaScreenRecorder.stop();
                }
              } else {
                vm.f_stopScreenShare();
                clearInterval(vm.d_shareScreenInterval);
              }
            }, 500);
          });
      }
      startCapture();
    },
    f_shareAudio: function () {
      this.d_sharingAudio = true;
      let vm = this;

      function file_read(blob) {
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          let client_data = {
            'type': 'meeting_share_audio',
            'audio': reader.result,
            'active_participants': vm.d_activeMeetingParticipants,
            'send_by': vm.user.username
          }
          vm.$socket.send(JSON.stringify(client_data));
        }, false);
        reader.readAsDataURL(blob);
      }
      async function startAudio() {
        await navigator.mediaDevices.getUserMedia({ 'audio': { 'echoCancellation': true, 'noiseSuppression': true, 'sampleRate': 16000 } })
          .then(function (stream) {
            vm.f_meetingShare('audio');
            var options;
            let is_type_supported = MediaRecorder.isTypeSupported('audio/webm;codecs=vp8');
            if (is_type_supported) {
              options = { mimeType: 'audio/webm;codecs=vp8' };
              mediaAudioRecorder = new MediaRecorder(stream, options);
            } else {
              mediaAudioRecorder = new MediaRecorder(stream);
            }
            // mediaAudioRecorder.setOutputFormat(MediaRecorder.OutputFormat.MPEG_4);
            // mediaAudioRecorder.setAudioEncoder(MediaRecorder.OutputFormat.AMR_NB);
            // mediaAudioRecorder.setAudioEncodingBitRate(16 * 44100);
            // mediaAudioRecorder.setAudioSamplingRate(44100);
            mediaAudioRecorder.start();
            var audio_chunks = [];
            mediaAudioRecorder.ondataavailable = function (e) {
              audio_chunks.push(e.data);
            }
            mediaAudioRecorder.onstop = function (e) {
              // console.log('audio_chunks ', audio_chunks);
              if (audio_chunks.length > 0) {
                // let blob = new Blob(audio_chunks, { type: "video/mp4" });
                let blob = null;
                if (is_type_supported) {
                  blob = new Blob(audio_chunks, { type: 'audio/webm;codecs=vp8' });
                } else {
                  blob = new Blob(audio_chunks, { type: 'audio/webm' });
                }
                file_read(blob);
              }
            }
            vm.d_shareAudioInterval = setInterval(() => {
              if (mediaAudioRecorder.state === 'recording') {
                if (vm.d_shareAudioInterval) {
                  mediaAudioRecorder.stop();
                  audio_chunks = [];
                  // stream.removeTrack(stream.getAudioTracks()[0]);
                  mediaAudioRecorder.start();
                } else {
                  mediaAudioRecorder.stop();
                }
              } else {
                stream.getAudioTracks()[0].enabled = false;
                vm.f_stopAudioShare();
                clearInterval(vm.d_shareAudioInterval);
              }
            }, 750);
          });
      }
      startAudio();
    },
    f_shareCamera: function () {
      this.d_sharingCamera = true;
      let vm = this;

      function file_read(blob) {
        var reader = new FileReader();
        reader.addEventListener("load", function () {
          let client_data = {
            'type': 'meeting_share_camera',
            'video': reader.result,
            'active_participants': vm.d_activeMeetingParticipants,
            'send_by': vm.user.username
          }
          vm.$socket.send(JSON.stringify(client_data));
        }, false);
        reader.readAsDataURL(blob);
      }
      async function startCamera() {
        await navigator.mediaDevices.getUserMedia({ 'video': { width: 1280, height: 720 } })
          .then(function (stream) {
            vm.f_meetingShare('camera');
            var options;
            let is_type_supported = MediaRecorder.isTypeSupported('video/webm;codecs=vp8');
            if (is_type_supported) {
              options = { mimeType: 'video/webm;codecs=vp8' };
              mediaCameraRecorder = new MediaRecorder(stream, options);
            } else {
              mediaCameraRecorder = new MediaRecorder(stream);
            }
            // mediaCameraRecorder.setOutputFormat(MediaRecorder.OutputFormat.MPEG_4);
            // mediaCameraRecorder.setAudioEncoder(MediaRecorder.OutputFormat.AMR_NB);
            // mediaCameraRecorder.setAudioEncodingBitRate(16 * 44100);
            // mediaCameraRecorder.setAudioSamplingRate(44100);
            mediaCameraRecorder.start();
            var camera_chunks = [];
            mediaCameraRecorder.ondataavailable = function (e) {
              camera_chunks.push(e.data);
            }
            mediaCameraRecorder.onstop = function (e) {
              // console.log('camera_chunks ', camera_chunks);
              if (camera_chunks.length > 0) {
                // let blob = new Blob(camera_chunks, { type: "video/mp4" });
                let blob = null;
                if (is_type_supported) {
                  blob = new Blob(camera_chunks, { type: 'video/webm;codecs=vp8' });
                } else {
                  blob = new Blob(camera_chunks, { type: 'video/webm' });
                }
                file_read(blob);
              }
            }
            vm.d_shareCameraInterval = setInterval(() => {
              if (mediaCameraRecorder.state === 'recording') {
                if (vm.d_shareCameraInterval) {
                  mediaCameraRecorder.stop();
                  camera_chunks = [];
                  // stream.removeTrack(stream.getAudioTracks()[0]);
                  mediaCameraRecorder.start();
                } else {
                  mediaCameraRecorder.stop();
                }
              } else {
                vm.f_stopCameraShare();
                clearInterval(vm.d_shareCameraInterval);
              }
            }, 500);
          });
      }
      startCamera();
    },
    f_joinMeeting: function (meeting_id) {
      localStorage.removeItem('meeting');
      let data = {
        'id': meeting_id
      };
      MessengerService.join_meeting(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            if (resp.data.result.wdmr_69) {
              this.d_meetingData = resp.data.result.wdmr_69;
              this.f_createParticipantList();
              localStorage.setItem('meeting', JSON.stringify(resp.data.result.wdmr_69));
            } else {
              alert('Aktif toplantı bulunamadı. Lütfen toplantı bilgisini kontrol ediniz.');
            }
          } else {
            alert('Error: ', resp.data.message);
          }
        })
    },
    f_createParticipantList: function () {
      this.d_activeMeetingParticipants = [];
      this.d_otherOneShare = false;
      if (this.d_meetingData && this.d_meetingData.id) {
        for (let i in this.d_meetingData.data.general.participant.list) {
          if (this.d_meetingData.data.general.participant.list[i].participant_status.val.value === 'active') {
            this.d_activeMeetingParticipants.push(this.d_meetingData.data.general.participant.list[i].participant_name.val.value);
          }
          // IF THIS IS NOT THE SHARING USER, AND SOMEONE SHARES,
          if (this.d_meetingData.data.general.participant.list[i].participant_screen_sharing.val.value === 'yes' && this.d_meetingData.data.general.participant.list[i].participant_name.val.value !== this.user.username) {
            this.d_otherOneShare = true;
          }
          if (this.d_meetingData.data.general.participant.list[i].participant_audio.val.value === 'yes' && this.d_meetingData.data.general.participant.list[i].participant_name.val.value !== this.user.username) {
            this.d_talkingUsers.push(this.d_meetingData.data.general.participant.list[i].participant_name.val.value);
          }
          if (this.d_meetingData.data.general.participant.list[i].participant_camera.val.value === 'yes' && this.d_meetingData.data.general.participant.list[i].participant_name.val.value !== this.user.username) {
            this.d_cameraUsers.push(this.d_meetingData.data.general.participant.list[i].participant_name.val.value);
          }
        }
      }
      this.$forceUpdate();
    },
    f_leaveMeeting: function (type) {
      let data = {
        'leave_type': type, // "this" or "all"
        'id': this.d_meetingData.id
      };
      MessengerService.leave_meeting(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_meetingData = '';
            localStorage.removeItem('meeting');
          } else {
            alert('Error: ', resp.data.message);
          }
        })
    },
    f_createNewMeeting: function () {
      let data = {
        'data': {
          'id': 'new',
          'label': this.d_meetingLabel
        }
      };
      MessengerService.save_meeting(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_meetingData = resp.data.result.wdmr_69;
            localStorage.setItem('meeting', JSON.stringify(this.d_meetingData));
          } else {
            alert('Error: ', resp.data.message);
          }
        })
    },
    f_calculateStyle: function () {
      let style = '';
      let x = 310;
      style = 'overflow-y: auto; height: ' + (window.innerHeight - x).toString() + 'px';
      return style;
    },
    f_calculateStyleMessageScreen: function () {
      let style = '';
      let x = '';
      if (this.d_screenSharingModejFullScreen) {
        x = 50;
      } else {
        x = 310;
      }
      style = 'border: solid 1px #adffdc; border-radius: 5px; padding: 5px; overflow-x: hidden; overflow-y: auto; height: ' + (window.innerHeight - x).toString() + 'px';
      return style;
    },
    f_getUserNameFromGroupList: function (username) {
      for (let i in this.d_selectedMessengerData.group_user_list) {
        if (username === this.d_selectedMessengerData.group_user_list[i].username) {
          return this.d_selectedMessengerData.group_user_list[i].first_name + ' ' + this.d_selectedMessengerData.group_user_list[i].last_name;
        }
      }
    },
    f_showChars: function (text, char_count) {
      if (text.length > 15) {
        return text.substring(0, 15) + '...';
      } else {
        return text.substring(0, 15);
      }
    },
    addEventListenerToMessageListScroll: function () {
      const handler = (evt) => {
        if (document.getElementById('messages-list') && document.getElementById('messages-list').scrollTop === 0) {
          // console.log('addEventListenerToMessageListScroll');
          this.d_pagination.current += 1;
          this.f_getMessagesList();
        }
      };
      if (this.d_showMessengerMode === 'message_screen') {
        setTimeout(function () {
          var div = document.getElementById("messages-list");
          div.addEventListener("scroll", handler);
        }, 50);
      } else {
        // console.log('remove scrool listener');
        document.getElementById('messages-list').removeEventListener('scroll', handler);
      }
    },
    f_saveMessengerGroup: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data['wdm20'], ['name', 'delete']);
      let wdmr_name = return_data.name;
      this.wisdom_data['label'] = wdmr_name;
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = { 'type': 'alert', 'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.', 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' * ' + wdmr_require_alerts[r].label;
        }
        let modal_data = { 'type': 'alert', 'text': require_txt, 'centered': true, 'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      // if the first time this messenger_group data is recording, we are gonna control whether this user is in the members list
      if (this.wisdom_data.id === 'new' && !this.wisdom_data.data.general.members[this.user.username]) {
        this.wisdom_data.data.general.members[this.user.username] = {
          'name': {
            'label': this.user.first_name + ' ' + this.user.last_name,
            'value': this.user.username
          },
          'detail': {
            'permissions': { 'val': [{ 'value': 'administrator', 'label': 'yönetici' }] }
          }
        }
      }
      // console.log(this.wisdom_data);
      // return;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Mesajlaşma grubu kaydediliyor.' } });
      MessengerService.save_messenger_group({ 'data': this.wisdom_data })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'Başarıyla Kaydedildi.';
          } else {
            msg = resp.data.message;
            alert('errror ', resp.data.message);
          }
          let modal_data = {
            'type': 'alert',
            'header_bg_variant': 'info',
            'header_text_variant': 'dark',
            'text': msg,
            'centered': true,
            'title': 'Mesajlaşma Grubu Kaydı Hakkında'
          };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_createNewGroup: function () {
      this.data_type = 'wdm20';
      let pre_data = {
        'id': 'new',
        'data': {},
        'type': 'wdm20',
        'reference_wdm': {}
      };
      pre_data['reference_wdm']['version'] = this.option_data[this.data_type]['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data[this.data_type]['owner_type'];
      pre_data['reference_wdm']['key'] = 'wdm_wisdom_' + this.data_type + '_v' + this.option_data[this.data_type].version;
      for (let i in this.option_data[this.data_type].param_group.param_order) {
        pre_data['data'][this.option_data[this.data_type].param_group.param_order[i]] = {};
      }
      pre_data['data']['general']['group_type'] = { 'val': { 'value': 'general', 'label': 'genel' } };
      this.wisdom_data = pre_data;
      this.d_modalMessengerGroup = true;
    },
    f_recommendText: function () {
      this.refreshIntervalId = setInterval(() => {
        this.d_recommendNow = [];
        this.d_recommendSelection.start += 2;
        this.d_recommendSelection.end += 2;
        if (this.d_recommendSelection.start >= this.d_recommendationSentenceList.length - 1) {
          this.d_recommendSelection.start = 0;
          this.d_recommendSelection.end = 2;
        } else if (this.d_recommendSelection.end > this.d_recommendationSentenceList.length - 1) {
          this.d_recommendSelection.end = this.d_recommendationSentenceList.length - 1;
        }
        for (let i = this.d_recommendSelection.start; i <= this.d_recommendSelection.end; i++) {
          this.d_recommendNow.push(this.d_recommendationSentenceList[i]);
        }
        this.$forceUpdate();
      }, 5000);
    },
    DateFormat: function (date) {
      if (date) {
        if (date.indexOf('T') === -1) {
          return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm");
        } else {
          return moment(date, "YYYY-MM-DDTHH:mm:ss").format("DD.MM.YYYY HH:mm");
        }
      } else {
        return "";
      }
    },
    f_messagesScrollFunc: function () {
      setTimeout(function () {
        document.getElementById('messages-list').scrollTop = document.getElementById('messages-list').scrollHeight + 20;
        this.$forceUpdate();
      }.bind(this), 500)
    },
    f_sendMessage: function (type = '', meeting_id = '') {
      if (type === 'meeting' && meeting_id) {
        this.d_sendText = meeting_id + ' toplantı nosu ile bağlantı gerçekleştirebilirsiniz.';
      }
      if (!this.d_sendText) {
        alert('Lütfen mesajınızı ekleyiniz');
        return;
      }
      let message_type = 'user';
      let is_airobot = 0;
      if (this.d_selectedMessengerData.user && this.d_selectedMessengerData.user.ai_robot) {
        is_airobot = 1;
        message_type = 'airobot';
      }
      let receiver = '';
      if (this.d_selectedMessengerData.user && this.d_selectedMessengerData.user.username) {
        receiver = this.d_selectedMessengerData.user.username;
        message_type = 'user';
      }
      if (this.d_selectedMessengerData.group) {
        receiver = this.d_selectedMessengerData.group.key;
        message_type = 'group';
      }
      let data = {
        'type': message_type, // user, group, airobot
        'message': this.d_sendText,
        'receiver': receiver,
        'is_airobot': is_airobot
      };
      if (type === 'meeting' && meeting_id) {
        data['meeting_id'] = meeting_id;
      }
      MessengerService.send_message_to_this_user(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            document.getElementById('sending-text').focus();
            // document.activeElement.blur();
            this.d_sendText = '';
            // We are controlling the !is_airobot statement, because when we received the result, we dont know whether the ai robot answered.
            // So we are listening the results on socket either to adding sender message or receiver message in ai_robot talk.
            if (!is_airobot) {
              let x = JSON.parse(JSON.stringify(this.messenger_data));
              if (!this.d_selectedMessengerData.message_list) {
                this.d_selectedMessengerData.message_list = []
              }
              this.d_selectedMessengerData.message_list.push(resp.data.result.message);
              let det = false;
              for (let d in x[this.selected_mes_ind].day_list) {
                if (x[this.selected_mes_ind].day_list[d].date === resp.data.result.messenger_data.day_list.date) {
                  x[this.selected_mes_ind].day_list[d] = resp.data.result.messenger_data.day_list;
                  det = true;
                  break;
                }
              }
              if (!det) {
                x[this.selected_mes_ind].day_list.push(resp.data.result.messenger_data.day_list);
              }
              this.$store.commit('messenger_data', x);
            }
            this.f_messagesScrollFunc();
          } else {
            console.log('error', resp.data.message);
          }
        })
    },
    f_hasNotSeenMessage: function (mes) {
      for (let i in mes['day_list']) {
        if (mes['day_list'][i].new) {
          return true;
        }
      }
      return false;
    },
    f_getMessagesList: function () {
      if (this.d_pagination.current !== 1) {
        this.d_pagination.start = (this.d_pagination.current - 1) * this.d_pagination.perpage;
        this.d_pagination.end = (this.d_pagination.perpage * this.d_pagination.current);
      }
      let all_messages_count = 0;
      for (let d in this.d_selectedMessengerData.day_list) {
        all_messages_count += this.d_selectedMessengerData.day_list[d].count;
      }
      if (all_messages_count > 0) {
        // example for explanation the below statement
        // pagination.start = 0
        // pagination.end = 10
        // all_messages_count = 13
        // then we are writing statement below. Because on the second current (= 2) =>>> start, end will be 10-20. So we have to take also between statement
        if ((all_messages_count > this.d_pagination.end) || (all_messages_count > this.d_pagination.start && all_messages_count < this.d_pagination.end)) {
          let data = JSON.parse(JSON.stringify(this.d_selectedMessengerData));
          data.pagination = this.d_pagination;
          let before_height = document.getElementById('messages-list').scrollHeight;
          MessengerService.get_messages_list(data)
            .then(resp => {
              if (resp.data.status === 'success') {
                // console.log(resp.data.result);
                // console.log('this.d_pagination: ', this.d_pagination);
                this.$forceUpdate();
                // If We are getting the message list for the first time, we can equal the result to message_list.
                // But if the current is higher than 1, we have to add new message array by concating to the start of the array to see upper in the page.
                if (this.d_pagination.current === 1) {
                  this.d_selectedMessengerData.message_list = resp.data.result.list;
                  if (resp.data.result.group_user_list) {
                    this.d_selectedMessengerData.group_user_list = resp.data.result.group_user_list;
                  }
                  this.f_messagesScrollFunc();
                } else {
                  setTimeout(function () {
                    let after_height = document.getElementById('messages-list').scrollHeight;
                    // console.log('after_height: ', after_height);
                    // console.log('before_height: ', before_height);
                    document.getElementById('messages-list').scrollTop = after_height - before_height;
                  }, 50);
                  this.d_selectedMessengerData.message_list = resp.data.result.list.concat(this.d_selectedMessengerData.message_list);
                }
              } else {
                console.log('error', resp.data.message);
              }
            });
        }
      }
    },
    f_selectThisUser: function (mes_ind) {
      this.selected_mes_ind = mes_ind;
      this.d_pagination = {
        'start': 0,
        'end': 10,
        'current': 1,
        'perpage': 10
      };
      // the event listener scroll handler in above function makes d_pagination.current += 1. The problem exists while we clicking any user, it looks at the scroll top. 
      // If the scrollTop is 0, then we have to make the current = 1, else we make the current = 0 because handler already itself adds + 1 to d_pagination.current in its function.
      this.d_showMessengerMode = 'message_screen';
      if (document.getElementById('messages-list') && document.getElementById('messages-list').scrollTop === 0) {
        this.d_pagination.current = 1;
      } else {
        this.d_pagination.current = 0;
      }
      this.d_selectedMessengerData = JSON.parse(JSON.stringify(this.messenger_data[mes_ind]));
      MessengerService.see_this_user_messages(this.d_selectedMessengerData)
        .then(resp => {
          if (resp.data.status === 'success') {
            let x = JSON.parse(JSON.stringify(this.messenger_data));
            for (let i in x[mes_ind].day_list) {
              this.$delete(x[mes_ind].day_list[i], 'new');
            }
            this.$store.commit('messenger_data', x);
            this.$forceUpdate();
            this.f_getMessagesList();
            this.f_messagesScrollFunc();
          } else {
            console.log('error', resp.data.message);
          }
        });
    },
    f_searchUser: function (mes) {
      if (mes.user && mes.user.username === this.user.username) {
        return false;
      }
      if (this.d_showUserMode === 'meeting') {
        if (this.d_meetingData && this.d_meetingData.id) {
          if (mes.user) {
            if (this.d_activeMeetingParticipants.indexOf(mes.user.username) === -1) {
              return false;
            }
          } else {
            return false;
          }
        }
      }
      if (this.d_showUserMode === 'my_hospital') {
        if (mes.user) {
          if (this.user.hospital_id !== mes.user.hospital_id) {
            return false;
          }
        } else {
          return false;
        }
      }
      if (this.d_showUserMode === 'ai_robot') {
        if (mes.user) {
          if (!mes.user.ai_robot) {
            return false;
          }
        } else {
          return false;
        }
      }
      if (this.user_search) {
        let x = '';
        if (mes.user) {
          x = mes.user.first_name + mes.user.last_name;
        } else if (mes.group) {
          x = mes.group.label;
        }
        if (x.toLocaleLowerCase('tr').indexOf(this.user_search.toLocaleLowerCase('tr')) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  },
  watch: {
    'd_showMessengerMode': function () {
      if (this.d_showMessengerMode === 'message_screen') {
        try {
          document.getElementById('video_element').innerHTML = '';
        } catch (err) {}
      }
    }

  },
  components: {
    Modal,
    WisdomDataModal
  }
};

</script>

<style type="text/css">
#messages-list::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #d9e9ea;
}

#messages-list::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#messages-list::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

